import React, { useState, useEffect } from "react"
import style from "./index.module.less"
import BlackDown from "@/images/SVG/blackDown.svg"
import { useIntl } from "react-intl"
import Axios from "axios"
import getCountry from '@/utils/getCountry.js'

const DetailFaq = ({ type }) => {
  const [openMore, setOpenMore] = useState(false)
  const [showList, setShowList] = useState([])
  const [moreList, setMoreList] = useState([])
  const [allList,setAllList]=useState([])
  const [nebulaAndroid,setNebulaAndroid]=useState([])
  const [nebulaMac,setNebulaMac]=useState([])
  const intl = useIntl()
  const region = getCountry()
  useEffect(() => {
    if(type=='nebula'){
      Axios.get(`${process.env.resourceUrl}docs/faq/${region}/nebula-for-android.json`).then(function (data) {
        setNebulaAndroid(data.data)
      })
      Axios.get(`${process.env.resourceUrl}docs/faq/${region}/nebula-for-mac.json`).then(function (data) {
        setNebulaMac(data.data)
      })
    }else{
      Axios.get(`${process.env.resourceUrl}docs/faq/${region}/${type}.json`).then(function (data) {
      setAllList(data.data)
      if (data.data.length > 6) {
        setShowList(data.data.slice(0, 6))
        setMoreList(data.data.slice(6))
      }else{
        setShowList(data.data)
      }
    })
    }
    
  }, [])

  useEffect(()=>{
    if(!!nebulaAndroid&&!!nebulaMac){
      let arr = [...nebulaAndroid,...nebulaMac]
      setAllList(arr)
      if (arr.length > 6) {
        setShowList(arr.slice(0, 6))
        setMoreList(arr.slice(6))
      }else{
        setShowList(arr)
      }
    }
  },[nebulaAndroid,nebulaMac])
  const clickFaqItem = e => {
    const answerNode = e.currentTarget.childNodes[1]
    answerNode.style.display =
      answerNode.style.display == "none" ? "block" : "none"

    const svgNode = e.currentTarget.childNodes[0].childNodes[1]
    svgNode.style.transform =
      svgNode.style.transform == "rotate(90deg)"
        ? "rotate(270deg)"
        : "rotate(90deg)"
  }

  return (
    <div className={style.box}>
      <div className={style.title}>FAQ</div>
      <div className={style.content}>
        {showList.map((item, index) => (
          <div
            className={style.contentItem}
            key={item.Question}
            onClick={e => {
              clickFaqItem(e)
            }}
          >
            <div className={style.contentItemQ}>
              <div className={style.contentItemQBox}>
                {/* <span /> */}
                {item.Question}
              </div>
              <BlackDown style={{ transform: "rotate(90deg)" }} />
            </div>
            <div className={style.contentA} style={{ display: "none" }}  dangerouslySetInnerHTML={{ __html: item.Answer}}>
             
            </div>
          </div>
        ))}
      </div>
      {allList?.length > 6 && (
        <>
          {!openMore && (
            <div
              className={style.more}
              onClick={() => {
                setOpenMore(!openMore)
              }}
            >
              {intl.formatMessage({ id: "see_all", defaultMessage: "more" })}
              <BlackDown
                style={{
                  transform: openMore ? "rotate(270deg)" : "rotate(90deg)",
                }}
              />
            </div>
          )}
          {openMore && (
            <div className={style.content}>
              {moreList.map((item, index) => (
                <div className={style.contentItem} key={item.Question} onClick={e => {
                  clickFaqItem(e)
                }}>
                  <div className={style.contentItemQ}>
                    <div className={style.contentItemQBox}>
                      {/* <span /> */}
                      {item.Question}
                    </div>
                    <BlackDown style={{ transform: "rotate(90deg)" }} />
                  </div>
                  <div className={style.contentA} style={{ display: "none" }}   dangerouslySetInnerHTML={{ __html: item.Answer}}></div>
                </div>
              ))}
              <div
                className={style.more}
                onClick={() => {
                  setOpenMore(!openMore)
                }}
              >
                {" "}
                <BlackDown
                  style={{
                    transform: openMore ? "rotate(270deg)" : "rotate(90deg)",
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default DetailFaq
